export const ApplicationAPI = {
    getApplicationHistory: 'application',
    addApplication: 'application',
    uploadDoc: 'applicationUploadDoc',
    getUploadDocuments: 'applicationUploadDoc?applicationId=',
    getAppFilters: 'application/agent/filter',
    verifyPassport: 'applicationUploadDoc/passportVerify',
    syncVerifyPassword: 'applicationUploadDoc/syncVerifyPassword',
    getApplicationById: 'application/getById/',
    getCallRecordings: 'callrecordings?applicationId=',
    getMailCommunication: 'mailCommunication?applicationId=',
    getMailDetails: '/incoming-email/mailDetails/',
    getComments: 'applicationComments/getById/',
    getcommentStatuses: 'applicationStatus/agentCommunicationStatus',
    postComment: 'applicationComments',
    instantApply: 'application/instantApply',
    createDoc: 'applicationUploadDoc/createDoc',
    updateApplication: 'application?applicationId=',
    extractStudentData: 'application/agent/export',
}