<div class="chat-container" *ngIf="showChatContainer">
    <div class="chat-box">
        <div class="chat-header">
            <ng-container *ngIf="!showChatBody">
                <i class="ri-chat-1-line chat-icon"></i>
                <h2>Crizac Chat</h2>
                <p>
                    We go beyond merely communicating to connecting with people.
                    Chat now!
                </p>
            </ng-container>
            <ng-container *ngIf="showChatBody">
                <div class="chat-user-head d-flex align-items-center">
                    <button
                        class="goback d-flex align-items-center justify-content-center"
                        (click)="goBack()"
                    >
                        <i class="flaticon-chevron-1"></i>
                    </button>
                    <div class="d-flex gap-x-2 header-content w-100">
                        <i class="ri-chat-1-line"></i>
                        <div class="w-100">
                            <h6>
                                {{ senderName ? senderName : "Crizac Chat" }}
                            </h6>
                            <p><span></span> Online</p>
                        </div>
                        <i
                            class="cursor-pointer d-sm-none {{
                                showChatContainer
                                    ? 'ri-close-line'
                                    : 'ri-menu-2-line'
                            }}"
                            (click)="showChatContainer = false"
                        ></i>
                    </div>
                </div>
            </ng-container>
        </div>
        <div class="chat-body">
            <div class="">
                <button
                    class="new-conversation"
                    *ngIf="!showChatBody"
                    (click)="withLogin?createChatRoom():withOutLoginChat()"
                >
                    {{
                        chatRoomId
                            ? "Continue conversation"
                            : "New conversation"
                    }}
                </button>
            </div>
            <ng-container *ngIf="showChatBody">
                <app-chat-content
                    [showChatBody]="showChatBody"
                    [chatRoomId]="chatRoomId"
                    [userDetails]="userDetails"
                    [isApplicationHistory]="isApplicationHistory"
                    (senderSenderName)="setSenderName($event)"
                    (resetConversion)="resetConversion($event)"
                    [withLogin]="withLogin"
                ></app-chat-content>
            </ng-container>
        </div>
    </div>
</div>
<button class="chat-button" (click)="!showLoader && initiateChat()">
    <mat-spinner
        *ngIf="showLoader"
        [strokeWidth]="10"
        color="warn"
    ></mat-spinner>
    <i
        *ngIf="!showLoader"
        class=" {{ showChatContainer ? 'ri-close-line' : 'ri-menu-2-line' }}"
    ></i>
</button>
