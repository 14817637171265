<div class="chat-content-body">
    <ng-container *ngIf="messages.length > 0">
        <div class="date">
            <span>Today</span>
        </div>
    </ng-container>
    <div class="chat-content" #chatContent>
        <ng-container *ngFor="let message of messages; index as i">
            <div class="chat {{ message?.isSend ? 'right' : 'left' }}">
                <div class="message">
                    <ng-container *ngIf="message?.text">
                        <div
                            appReadMore
                            [appReadMoreFullText]="message?.text"
                            [appReadMoreMaxLength]="150"
                        ></div>
                        <ng-container *ngIf="message?.isConfirm">
                            <div
                                class="d-flex py-2 gap-x-2 justify-content-end"
                            >
                                <span
                                    class="material-symbols-outlined cursor-pointer"
                                    matTooltip="Confirm"
                                    (click)="closeConversion()"
                                    >thumb_up</span
                                >
                                <span
                                    class="material-symbols-outlined cursor-pointer"
                                    (click)="continueChat()"
                                    matTooltip="Continue Chat"
                                    >thumb_down</span
                                >
                            </div>
                        </ng-container>
                    </ng-container>
                    <ng-container *ngIf="message?.file?.fileName">
                        <ng-container *ngIf="message?.file?.isImage">
                            <img [src]="message?.file?.file" />
                        </ng-container>
                        <ng-container
                            *ngIf="
                                message?.file?.fileType === 'application/pdf'
                            "
                        >
                            <div class="d-flex gap-x-1 align-items-center">
                                <a
                                    (click)="
                                        downloadPdf(
                                            message.file?.file,
                                            message?.file?.fileName
                                        )
                                    "
                                    class="cursor-pointer no-text d-flex"
                                >
                                    <span
                                        class="material-symbols-outlined fs-20"
                                        >attach_file</span
                                    >
                                    <span>{{ message?.file?.fileName }}</span>
                                </a>
                            </div>
                        </ng-container>
                        <ng-container
                            *ngIf="message?.file?.fileType === 'audio/mp3'"
                        >
                            <div class="d-flex gap-x-1 align-items-center">
                                <span
                                    #playButtonRef
                                    class="material-symbols-outlined cursor-pointer"
                                    (click)="onPlayButtonClick($event)"
                                    >play_circle</span
                                >
                                <div>
                                    <p class="m-0">
                                        {{ message?.file?.duration }}
                                    </p>
                                    <!-- <p
                                        class="m-0 fs-12 fw-semibold pt-1 d-flex gap-x-2"
                                    >
                                        {{ message?.file?.duration }}
                                    </p> -->
                                </div>
                                <audio #audioElementRef style="display: none">
                                    <!-- Hide the audio element -->
                                    <source
                                        type="audio/mp3"
                                        [src]="message.file.file"
                                    />
                                </audio>
                            </div>
                        </ng-container>
                    </ng-container>
                </div>
                <div class="time">{{ message.time }}</div>
            </div>
        </ng-container>
    </div>
    <div class="editor">
        <div class="chat-input">
            <div class="w-100">
                <ng-container *ngIf="!isAudioRecording; else recording">
                    <textarea
                        class="textarea"
                        id="textarea"
                        *ngIf="!audioBlobUrl"
                        placeholder="Write a message..."
                        (input)="onTextareaInput($event)"
                        (keydown.enter)="handleEnter($event)"
                        [formControl]="textInput"
                    ></textarea>
                    <!-- <div
                        id="textarea"
                        class="textarea"
                        contenteditable="true"
                        data-placeholder="Type something here..."
                        (input)="onTextareaInput($event)"
                    ></div> -->

                    <ng-container *ngIf="fileName && !isSend">
                        <span class="d-flex gap-x-2">
                            {{ fileName }}
                            <span
                                class="material-symbols-outlined cursor-pointer"
                                (click)="removeFile()"
                                >close</span
                            ></span
                        >
                    </ng-container>
                    <div class="d-flex gap-x-2 align-items-center">
                        <span
                            *ngIf="!isAudioRecording && audioBlobUrl"
                            class="material-symbols-outlined cursor-pointer"
                            (click)="clearAudioRecordedData()"
                            >close</span
                        >
                        <audio
                            *ngIf="!isAudioRecording && audioBlobUrl"
                            controls
                        >
                            <source [src]="audioBlobUrl" type="audio/mp3" />
                        </audio>
                    </div>
                </ng-container>
                <ng-template #recording>
                    <div
                        id="audioTimer"
                        *ngIf="isAudioRecording && !audioBlobUrl"
                    >
                        {{ audioRecordedTime }}
                    </div>
                </ng-template>
            </div>

            <div class="chat-buttons">
                <ng-container
                    *ngIf="
                        !textInput?.value && !fileName && !audioBlobUrl;
                        else send
                    "
                >
                    <ng-container *ngIf="!isAudioRecording && !audioBlobUrl">
                        <input
                            type="file"
                            style="width: 0; height: 0"
                            accept=".pdf, .jpeg, .jpg, .png"
                            #fileInput
                            (change)="onFileSelected($event)"
                        />
                        <span
                            class="material-symbols-outlined"
                            (click)="triggerFileInput(fileInput)"
                            >attach_file</span
                        >
                        <span
                            class="material-symbols-outlined"
                            (click)="videoMeeting()"
                            *ngIf="false"
                            >videocam</span
                        >
                    </ng-container>
                    <span
                        class="material-symbols-outlined {{
                            isAudioRecording && 'text-danger'
                        }}"
                        (click)="
                            isAudioRecording
                                ? stopAudioRecording()
                                : startAudioRecording()
                        "
                        *ngIf="false"
                    >
                        {{ isAudioRecording ? " stop_circle" : "mic" }}
                    </span>
                    <ng-container *ngIf="!isAudioRecording && !audioBlobUrl">
                        <!-- <span class="material-symbols-outlined"
                            >sentiment_satisfied</span
                        > -->

                        <div
                            class="show-emoji"
                            [ngStyle]="{
                                visibility: showEmoji ? 'visible' : 'hidden'
                            }"
                        >
                            <emoji-picker></emoji-picker>
                        </div>
                    </ng-container>
                </ng-container>
                <ng-template #send>
                    <!-- <span class="material-symbols-outlined"
                        >sentiment_satisfied</span
                    > -->

                    <mat-spinner
                        *ngIf="showSpinner"
                        [strokeWidth]="10"
                        color="primary"
                    ></mat-spinner>
                    <span
                        *ngIf="!showSpinner"
                        class="material-symbols-outlined"
                        (click)="onSend()"
                        >send</span
                    >
                </ng-template>
                <!-- <span class="material-symbols-outlined" (click)="showConfirm()"
                    >check</span
                > -->
            </div>
        </div>
    </div>
    <!-- <emoji-picker></emoji-picker> -->
</div>
