export const chatApi = {
    createSupportChatRooms: 'supportChatRooms/create',
    closeSupportChatRooms: 'supportChatRooms/update',
    sendMessageInChatRoom: 'supportChatMessages/agent',
    supportChatChannel: 'support-chat-notification',
    getChatRoomsMessages: 'supportChatMessages/agent',
    updateMeeting: 'supportChatRooms/update',
    createMeeting: 'supportChatRooms/createVideoCall',
    chatRoomId: 'roomId',
    createSupportChatRoomsWithOutLogin: '/supportChatRooms/createWithOutLogin?tempLoginId=:tempLoginId',
    getChatRoomsMessagesWithOutLogin: 'supportChatRooms/getsupportChatRoomsWithOutLogin?chatStatus=0&tempLoginId=:tempLoginId',
    sendMessageInChatRoomWithoutLogin: 'supportChatMessages/agent/withOutLogin',
};
