import {
    Component,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
} from '@angular/core';
import { ChatService } from './services/chat.service';
import { LocalStorageService } from 'src/app/core/services/localStorage/localStorage.service';
import { filePathWithBaseURL } from 'src/app/core/helpers/helper.function';
import { PopUpService } from 'src/app/core/services/pop-up/pop-up.service';
import { ApplicationService } from 'src/app/core/services/application/application.service';
import { lastValueFrom, Subject, takeUntil } from 'rxjs';
import { LoaderService } from '../../core/services/loader/loader.service';
import { chatApi } from 'src/app/core/constants/api-constant/chat-api.const';
import { StorageConst } from 'src/app/core/constants/storage.const';

@Component({
    selector: 'app-chat',
    templateUrl: './chat.component.html',
    styleUrls: ['./chat.component.scss'],
})
export class ChatComponent implements OnInit, OnChanges {
    @Input() chatPayload: any = '';
    @Input() withLogin: boolean = true;
    @Input() startChatFromOut?: boolean = true;
    message: string;
    senderName: string = '';
    messages: string[] = [];
    filePathWithBaseURL = filePathWithBaseURL;

    showChatBody: boolean = false;
    showChatContainer: boolean = false;
    currentPageName: string = '';
    isApplicationHistory: boolean = false;
    applicationDetails: any = {};
    applicationId: string = '';
    chatRoomId: string = '';
    userDetails: any;
    showLoader: boolean = false;
    private _unsubscribeAll: Subject<any> = new Subject();

    constructor(
        private _chatService: ChatService,
        private _localStorageService: LocalStorageService,
        private _popUpService: PopUpService,
        private _applicationService: ApplicationService,
        private _loaderService: LoaderService
    ) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (!this._localStorageService.getKey(chatApi.chatRoomId)) {
            if (
                changes &&
                changes['chatPayload'] &&
                changes['chatPayload'].currentValue
            ) {
                // this.createChatRoom(
                //     changes['chatPayload'].currentValue?.id,
                //     changes['chatPayload'].currentValue?.name
                // );
                if(this.withLogin){
                    this.generateNewChat(
                        changes['chatPayload'].currentValue?.id,
                        changes['chatPayload'].currentValue?.name
                    )
                }else{
                    this.withOutLoginChat();
                }
            }
        }

        if(this.startChatFromOut){
            this.showChatContainer = true;
            this.showChatBody = true;
        }
    }

    async generateNewChat(userId: string = '', name: string) {
        await this.createChatRoom();
        const now = Math.floor(Date.now() / 1000);
        let payload: any = {
            assignUser: [
                {
                    profile: '',
                    updatedTime: now,
                    userId,
                    name,
                },
            ],
            chatUpdate: now, // unix format feedback dateand time
        };
        await this.assignChatToUser(payload);
        this.showChatContainer = !this.showChatContainer;
    }

    ngOnInit(): void {
        this.chatRoomId = this._localStorageService.getKey(chatApi.chatRoomId);
        this._chatService.getapplicationId.subscribe({
            next: (res: string) => {
                console.log(res, this.chatRoomId);
                if (res) {
                    this._loaderService.showLoader();
                    this.showLoader = true;
                    this.isApplicationHistory = true;
                    this.applicationId = res;
                    this._applicationService
                        .getApplictionHistortById(Number(this.applicationId))
                        .pipe(takeUntil(this._unsubscribeAll))
                        .subscribe({
                            next: (response: any) => {
                                if (
                                    response?.statusCode === 200 &&
                                    response?.success &&
                                    response?.data &&
                                    response?.data.length > 0
                                ) {
                                    this.applicationDetails = response?.data[0];
                                    if (this.chatRoomId) {
                                        this._chatService
                                            .getChatRoomMessages(
                                                this.chatRoomId
                                            )
                                            .subscribe({
                                                next: (res) => {
                                                    if (
                                                        res?.statusCode ===
                                                            200 &&
                                                        res?.success &&
                                                        res?.data &&
                                                        res?.data?.data &&
                                                        res?.data?.data
                                                            ?.chatList &&
                                                        res?.data?.data
                                                            ?.chatList
                                                            .length === 0
                                                    )
                                                        this.assignChat(
                                                            this
                                                                .applicationDetails
                                                        );
                                                },
                                            });
                                    }
                                }
                                this.showLoader = false;
                                this._loaderService.hideLoader();
                            },
                            error: (err) => {
                                this.showLoader = false;
                                console.log(err);
                                this._loaderService.hideLoader();
                            },
                        });
                } else {
                    this.isApplicationHistory = false;
                    this.applicationDetails = null;
                }
            },
        });
    }

    async assignChat(res: any) {
        const now = Math.floor(Date.now() / 1000);
        let payload: any = {
            assignUser: [
                {
                    profile: '',
                    updatedTime: now,
                },
            ],
            chatUpdate: now, // unix format feedback dateand time
        };
        if (res?.caseOwnerDetails?.id) {
            payload = {
                ...payload,
                userId: res?.caseOwnerDetails?.id,
                name: `${res?.caseOwnerDetails?.counsellorDetails?.firstName} ${res?.caseOwnerDetails?.counsellorDetails?.lastName}`,
            };
        } else if (res?.agentRmDetails?.id) {
            payload = {
                ...payload,
                userId: res?.agentRmDetails?.id,
                name: `${res?.agentRmDetails?.userDetails?.firstName} ${res?.agentRmDetails?.userDetails?.lastName}`,
            };
        } else {
            return;
        }
        this.assignChatToUser(payload);
    }

    async assignChatToUser(payload: any) {
        const res = await lastValueFrom(
            this._chatService
                .assignChat(payload, this.chatRoomId)
                .pipe(takeUntil(this._unsubscribeAll))
        );
        console.log(res);
    }

    async createChatRoom(id: string = '', name: string = '') {
        this.userDetails = this._localStorageService.getUser();
        let payload: any = {
            name: `${this.userDetails?.firstName} ${this.userDetails?.lastName}`,
            agentId: this.userDetails?.agentId,
            companyName: this.userDetails?.company,
            profilePic: this.userDetails?.upload?.path
                ? filePathWithBaseURL(this.userDetails?.upload?.path)
                : '',
            feedback: '',
            chatUpdate: '',
            agentRm: this.userDetails?.agentRmDetails?.id || '',
            chatStatus: '0',
            status: 'true',
        };
        if (this.isApplicationHistory) {
            if (this.applicationDetails?.caseOwnerDetails?.id) {
                payload = {
                    ...payload,
                    caseOwner: this.applicationDetails?.caseOwnerDetails?.id,
                };
            } else if (this.applicationDetails?.agentRmDetails?.id) {
                payload = {
                    ...payload,
                    caseOwner: this.applicationDetails?.agentRmDetails?.id,
                };
            }

            payload = {
                ...payload,
                applicationId: this.applicationId,
                // caseOwner: this.applicationDetails?.caseOwner
                //     ? this.applicationDetails?.caseOwner[0]?.userId
                //     : this.applicationDetails?.agentRmDetails?.userDetails
                //           ?.userId,
            };
        }

        const res = await lastValueFrom(
            this._chatService
                .createRoom(payload)
                .pipe(takeUntil(this._unsubscribeAll))
        );

        if (res?.statusCode === 200 && res?.success && res?.data?.id) {
            this.showChatBody = true;
            this.chatRoomId = res?.data?.id;
            this._localStorageService.setKey(chatApi.chatRoomId, res?.data?.id);
        }
    }

    goBack() {
        this._popUpService.showConfirmationPopup(
            'Are you sure?',
            'You want to close this chat?',
            'Yes',
            (isConfirmed: boolean) => {
                if (isConfirmed) {
                    if(!this.withLogin){
                        this._localStorageService.removeKey('roomId');
                        this.confirmGoBack();
                        this.showChatContainer = false;
                        this.showChatBody = false;
                        return;
                    }
                    this.confirmGoBack();
                }
            },
            false,
            'No'
        );
    }

    resetConversion(event) {
        const roomId = this._localStorageService.getKey(chatApi.chatRoomId);
        if (!roomId) return;
        const unixTime = Math.floor(Date.now() / 1000);
        const payload = {
            feedback: event?.feedback,
            chatUpdate: unixTime,
            chatStatus: '1',
        };
        this._chatService.closeRoom(payload, roomId).subscribe({
            next: (res) => {
                if (res?.statusCode === 200 && res?.success) {
                    this.showChatContainer = false;
                    this._localStorageService.removeKey(chatApi.chatRoomId);
                    this.chatRoomId = '';
                }
            },
        });
        this.showChatBody = event?.status;
    }

    confirmGoBack() {
        this.showChatBody = false;
    }

    initiateChat() {
        this.showChatContainer = !this.showChatContainer;
    }

    setSenderName(event) {
        this.senderName = event;
    }

    withOutLoginChat(){
        let fetchedGeneratedId = JSON.parse(this._localStorageService.getKey(StorageConst.cz_temGenId));
        console.log(fetchedGeneratedId);
        this._loaderService.showLoader();
        let payload = {
          tempLoginId: fetchedGeneratedId,
        }
    
        this._chatService.getChatRoomMessagesWithOutLogin(payload)
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((rsv)=>{
          this._loaderService.hideLoader();
          console.log(rsv);
          let data = rsv?.data;
          if(data?.total !== 0){
            this.showChatBody = true;
            this.chatRoomId = rsv?.data?.data?.[0].id;
            this._localStorageService.setKey(chatApi.chatRoomId, rsv?.data?.data?.[0].id);
            // alert('We have chat')s
          }else{
            this._chatService.createRoomWithOutLogin(payload)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((rsv)=>{
              this._loaderService.hideLoader();
              console.log(rsv);
              this.showChatBody = true;
            this.chatRoomId = rsv?.data?.id;
            this._localStorageService.setKey(chatApi.chatRoomId, rsv?.data?.id);
            },
            (err)=>{
              this._loaderService.hideLoader();
            })
          }
        },
        (err)=>{
          this._loaderService.hideLoader();
        })
    
    
        
    }
}
